import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";

const query = graphql
`
query predlogi {
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/predlogi"}}}) {
    edges {
      node {
        id
        frontmatter {
          path
          navTitle
        }
        excerpt
        html
        rawMarkdownBody
      }
    }
  }
}
`;

const PredlogiPage = props => (
  <Layout navTitle={props.data.frontmatter.navTitle}>
    <div dangerouslySetInnerHTML={{ __html: props.data.html }}/>
  </Layout>
);

export default () => (
  <StaticQuery
    query={query}
    render={ data => (
      <PredlogiPage data={data.allMarkdownRemark.edges[0].node}/>
    )}
  />
);
